

<script>
import TeamSnap from '@/mixins/TeamSnap'

export default {
  name: 'Callback',
  mixins: [TeamSnap],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
    var token = this.$route.hash
    token = token.substring(0, token.indexOf('&'));
    token = token.substring(token.lastIndexOf("=") + 1);
    sessionStorage.setItem('teamsnap.authToken', token)
    this.$router.replace({
      path: this.$route.query.path
    })
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone||iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>